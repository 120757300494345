@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,300;1,500;1,700&family=Barlow:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

* {
    font-family: 'Barlow', sans-serif;
font-family: 'Barlow Condensed', sans-serif;
font-size: 16px;
letter-spacing: .1em;
box-sizing: border-box;
margin: 0;
}
body {
    min-height: 100vh;
    background: #EFF3FD;
}
button[role=button],input {
    min-height: 40px;
}
button[role=button],button[role=switch].ant-switch-checked {
    background-color: #1b3e77 !important;
}

.otpWrapper .ant-form-item-required {
    align-items: end !important;
}
.loginWrapper button {
    min-height: 40px;
    width: 100%;
    margin-top: 12px;
}
.loginWrapper .flex {
    width: 60%;
    justify-content: space-between;
    margin: auto;
    background: #fff;
    padding: 5%;
    margin-top: 8%;
}
.otpWrapper {
    flex-grow: .5;
}
.flex{
    display: flex;
    align-items: center;
}
.headerWrapper ul {
    list-style: none;
    justify-content: space-between;
    color: #fff;
}
.headerWrapper  {
    justify-content: space-between;
    /* background-image: linear-gradient(to right,#be1d2d,#1b3e77); */
    padding: 12px 32px;
    background: #1b3e77;
}
.pcSwitchWrapper {
    padding: 32px;
    padding-top: 72px;
}
.pcSwitchWrapper h1 {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    border-bottom: 1px solid #D9D9D9;
    width: fit-content;
    margin: auto;
    padding-bottom: 10px;
    margin-bottom: 22px;
}
#pcSwitch {
    margin-left: 12px;
}
.rightNav_items {
    flex-grow: .2;
}
.headerWrapper  img {
    max-width: 152px;
}
.pcDisabled h4 {
    margin-bottom: 12px;
}
.pcDisabled {
    max-width: 50%;
    margin: auto;
    background: #fff;
    padding: 5% 32px;
    text-align: center;
    border-radius: 5px;
}
.pcDisabled {
    color: #4F504F;
}
.pcDisabled svg {
    font-size: 132px;
    margin-bottom: 32px;
}
.tablesWrapper {
    padding: 22px;
}
.tablesWrapper .ant-col {
    margin: 12px;
}
.tablesWrapper .ant-col-12:first-child {
    width: 40%;
    max-width: 40%;
}
.tablesWrapper .ant-col-12:last-child {
    min-width: 56%;
}
.bgWhite {
    background: #fff;
}
.bgBlue {
    background: #1b3e77;
}
.textWhite {
    color: #fff;
}
 .ant-slider-track {
    background-color: #1b3e77 !important;
}
 .ant-slider-rail {
    background-color: rgba(0, 42, 78, 0.38) !important;
}
 .ant-slider-handle::after {
    box-shadow: 0 0 0 2px #000 !important;
}
.ant-collapse-content-box {
    padding: 22px !important;
    padding-top: 0 !important;

}
.fc-scrollgrid.fc-scrollgrid-liquid {
    border: 0;
}
.ant-slider-rail::before{
 content: "0 AM";
 position: absolute;
 left: -2px;
 top: 6px;
 font-size: 14px;
}
.ant-collapse-content-active {
    background-color: transparent !important;
    border: 0 !important;
    padding-bottom: 10px;
}
.ant-slider-step::before {
    content: "|";
    position: absolute;
    left: -2px;
    top: -15px;
    font-size: 20px;
    color: rgba(0, 42, 78, 0.38) !important;
    font-weight: bold; 
}
.ant-slider-step::after {
    content: "|";
    position: absolute;
    right: -4px;
    top: -15px;
    font-size: 20px;
    color: rgba(0, 42, 78, 0.38) !important;
    font-weight: bold;
}
.ant-slider-rail::after{
    content: "23 PM";
    position: absolute;
    right: -8px; 
    top: 8px;
    font-size: 14px;
   }
   .text-center {
    text-align: center;
   }
   .j-center {
    justify-content: center;
   }
   .csBg {
    background: #00FD28;
   }
   .ohBg {
    background: #FF9900;
   }
   .shBg {
    background: #FFE600;
   }
   .ftBg {
    background: #4C81D6;
   }
   .gmBg {
    background: #AFD2B4;
   }
   .cpBg {
    background: #FF70DF;
   }
   .fc-event.fc-event-start,.fc-event.fc-event-end {
    border: 0;
    padding: 2px 12px;
   }
   .fc .fc-col-header-cell-cushion {
    font-weight: 500;
   }
.dashboard h4 {
    padding: 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    align-items: center;
    font-weight: 500;
}
.fc-event-main i {
    font-size: 14px;
}
.d-flex {
    display: flex;
    height: 500px;
    min-height: fit-content;
}
.dashboard h4 svg {
    font-size: 20px;
    margin-right: 12px;
}
:where(.css-dev-only-do-not-override-k83k30) a {
    color: initial !important;
}
.fc-header-toolbar.fc-toolbar  {
    margin: 12px !important;
}
.fc-toolbar-title {
    font-size: 16px !important;
    padding-left: 12px;
    font-weight: 500;
}
.fc .fc-button-primary {
    background-color: #1b3e77 !important;
}
.round {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin: 0px 12px;
    margin-left: 22px;
}
.ant-collapse-expand-icon {
    display: none !important; 
}
.ant-collapse-header {
    padding: 32px 20px !important;
}
.ant-collapse-icon-position-start {
    border-radius: 0;
}
#proof {
    background-color: #fff;
    padding: 20px;
}
.cl-custom-check {
    display: none;
}
.cl-custom-check + .cl-custom-check-label {
   /* Unchecked style */
    background-color: #ccc;
    color: #fff;
    padding: 5px 10px;
    font-family: sans-serif;
    cursor: pointer;
    user-select: none;
    border-radius: 4px;
    display: inline-block;
    margin: 0 10px 10px 0;
    backface-visibility: hidden;
    transition: all 0.6s ease;
}
.timeStatus {
    margin-bottom: 22px;
}
.fc-theme-standard td {

    max-width: 12px;
}

.fc .fc-daygrid-day-top {
    justify-content: center;
}
.cl-custom-check:checked {
   /* Checked style */
}
.cl-custom-check:checked + .cl-custom-check-label {
    background-color: rgba(255, 112, 223, 0.5);
    backface-visibility: hidden;
    transform: rotateY(360deg);
}
.ant-collapse-header-text >div{
    display: flex;
    justify-content: space-between;
}
.ant-collapse-header-text >div>div {
    display: flex;
    align-items: center;
}
.green {
    background-color: green;
  }
  .yellow {
    background-color: yellow;
  }
  .red {
    background-color: red;
  }
  .timeStatus_wrapper {
    display: flex;
    flex-direction: column;
  }
  .singleTime_status {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .d-none {
    display: none !important;
  }
  .hour-box {
    padding: 6px 10px;
    margin: 2px;
    border-radius: 5px;
    border: 1px solid #C1C1C1;
    font-size: 14px;
}
.timeStatus_wrapper.slot2 {
    margin-top: 22px;
}
.timeFrame>div {
    display: flex;
    align-items: center;
    width: 85%;
    flex-wrap: wrap;
}
.advanceSingle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 32px 0;
    padding-right: 15%;
    cursor: pointer;
    position: relative;
}
.advanceSingle::after {
    content:'';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px ;
    border-bottom: 1px solid #D9D9D9;
    width: 90%;
    margin: auto;
}
.advanceSingle .round {
    margin: 0px 20px;
}
.advanceSingle>div {
    display: flex;
    align-items: center;
}
.timeTitle {
    font-weight: 500;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    flex-grow: 1;
    height: 100%;
    display: block;
    text-align: center;
    padding: 22px 0;
    margin-right: 20px;
    min-width: 150px;
    text-transform: capitalize;
    min-height: 65px;
}
.timeStatus >div {
    padding: 16px;
    padding-bottom: 32px;
}
.colCs {
    background: #00FD28;
}
.colOh {
    background: #FF9900;
}
.colSh {
    background: #FFE600;
}
.colFt {
    background: #4C81D6;
}
.colGm {
    background: #AFD2B4;
}
.colBa {
    background: #FF70DF;
}


@media(max-width:768px) {
    .tablesWrapper .ant-col {
        max-width: 100% !important;
        min-width: 100% !important;
    }
}




ul.ks-cboxtags {
    list-style: none;
    padding: 22px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}
ul.ks-cboxtags li{
    display: inline;
    margin-right: 20px;
    margin-bottom: 8px;
}
.ant-picker.ant-picker-range  {
    width: 50%;
    max-height: 42px;
}
.ant-space {
    min-width: 100%;
}
ul.ks-cboxtags li label{
    display: inline-block;
    background-color: rgba(255, 255, 255, .9);
    border: 2px solid rgba(139, 139, 139, .3);
    color: #adadad;
    border-radius: 25px;
    white-space: nowrap;
    margin: 3px 0px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    transition: all .2s;
}

ul.ks-cboxtags li label {
    padding: 8px 12px;
    cursor: pointer;
}

ul.ks-cboxtags li label::before {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 12px;
    padding: 2px 6px 2px 2px;
    content: "\f067";
    transition: transform .3s ease-in-out;
}
.accordion .multiTimeWrapper {
    text-transform: capitalize;
    margin: 22px 0px;
    margin-bottom: 26px;
}
.accordion select {
    border-color: #ccc;
    padding: 4px;
}
.multiTimeWrapper input {
    padding: 4px;
    border-color: #ccc;
}
ul.ks-cboxtags li input[type="checkbox"]:checked + label::before {
    content: "\f00c";
    transform: rotate(-360deg);
    transition: transform .3s ease-in-out;
}

ul.ks-cboxtags li input[type="checkbox"]:checked + label {
    border: 2px solid #1b3e77;
    background-color: #1b3e77;
    color: #fff;
    transition: all .2s;
}
.blacklist h5, .whitelist h5 {
    margin-bottom: 12px !important;
}
.blacklist span, .whitelist span {
    margin-bottom: 4px;
    display: block;
}
.blacklist {
    margin-bottom: 42px;
}
ul.ks-cboxtags li input[type="checkbox"] {
  display: absolute;
}
ul.ks-cboxtags li input[type="checkbox"] {
  position: absolute;
  opacity: 0;
}
ul.ks-cboxtags li input[type="checkbox"]:focus + label {
  border: 2px solid #1b3e77;
}
.applyBtn {
    background: #24529C;
    border-radius: 40px;
    min-height: 40px;
    padding: 0 32px;
    border: 0;
    color: #fff;
    margin-left: auto;
    cursor: pointer;
    display: block;
}
.rightNav_items a {
    color: #fff;
    text-decoration: none;
}
.domainInput input {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    color: #656575;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    height: 52px;
    margin: 32px 0;
    margin-bottom: 22px;
    padding: 12px;
}
.whitelistContainer ul, .blacklistContainer ul {
  list-style: decimal;
  padding: 0;
}
.whitelistContainer ul li, .blacklistContainer ul li {
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.domainInput>form {
    padding-bottom: 62px;
}
.whitelistContainer h4,.blacklistContainer h4 {
    padding: 12px;
    font-weight: 400;
}
.blacklistMenuWrapper .antdAccordionWrapper {
    padding: 32px;
}
button.delete {
    background: none;
    font-size: 14px;
    color: red;
    display: block;
    border: 1px solid;
    cursor: pointer;
}
.ant-drawer-body .flex {
    flex-direction: column;
    align-items: flex-start;
    list-style: none;
    padding: 0;
}
.ant-drawer-header,.ant-drawer-body {
    padding: 0 !important;
}
.ant-drawer-content-wrapper {
    background: #1b3e77;
    max-width: 200px;
}
.ant-drawer-content {
    background: #1b3e77 !important;
    color: #fff;
}
.ant-drawer-title {
    font-weight: 600 !important;
    font-size: 18px !important;
    border-bottom: 1px solid #fff;
    padding-bottom: 2px;
    margin-bottom: 32px !important;
    color: #fff !important;
    padding: 12px;
}
.ant-drawer-close {
    display: none !important;
}
.ant-drawer-body a {
    color: #fff !important;
}
.ant-drawer-body li {
    margin: 12px !important;
}
.faq .ant-collapse {
    padding: 22px;
    padding-top: 52px;
}
.faq .ant-collapse-header {
    background: transparent;
    color: #fff;
    border-radius: 12px;
    padding: 18px !important;
    margin-bottom: 22px;
    border: 2px solid #1b3e77;
}
.faq .ant-collapse-header-text {
    color: #1b3e77;
    font-size: 18px;
    font-weight: 500;
}
#tour {
    position: absolute;
    right: 0;
    border-radius: 0;
    background-color: #1b3e77;
    margin: 2px;
    margin-right: 0;
}
.pcDisabled>h1 {
    font-size: 32px;
    margin-bottom: 42px;
}
.ant-btn-primary,.ant-tour-prev-btn {
    height: 34px !important;
    color: #fff;
    background: #1b3e77;
}
.ant-tour-slider-active {
    background: #1b3e77;
}
.ant-tour-title {
    font-size: 17px !important;
}
.faq .ant-collapse-expand-icon {
    display:flex !important;
    margin-top: 4px;
}
.faq .ant-collapse-item {
    margin-bottom: 22px;
}
#logout {
    cursor: pointer;
}
.reportTable {
    margin: 72px 0;
}
.reportTable td {
    border: 1px solid #ccc;
    padding: 12px;
    font-size: 15px;
}
.reportWrapper.container {
    padding: 42px;
}
.reportTable th {
    padding: 8px;
}
.reportTable h1{
    margin-bottom: 42px;
    font-size: 20px;
}
.reportTable button {
    background: #be1d2d;
    border-color: #be1d2d;
    color: #fff;
    padding: 4px 8px;
}
.fc-daygrid-day-number {
    color: initial !important;
}
a.fc-col-header-cell-cushion {
    color: initial !important;
}
.blacklistMenuWrapper .tablesWrapper .ant-col-12:first-child,.blacklistMenuWrapper .tablesWrapper .ant-col-12:last-child {
    width: 46%;
    max-width: 46%;
    min-width: initial;
    margin-left: auto;
    margin-right: auto;
}
ul.ks-cboxtags li input[type="checkbox"]:disabled + label {
  opacity: .25;
}  
.defaultBtn {
    background: transparent;
    border: 1px solid #24529C;
    color: #24529C;
    padding: 4px 8px;
    border-radius: 2px;
    cursor: pointer;
}
.redError {
    color: red;
    position: absolute;
    right: 0;
    bottom: 46px;
    font-size: 14px;
}
.error {
    color: red;
    font-size: 14px;
}
#basic{
    position: relative;
}

.react-datepicker {
    width: 100%;
}
.react-datepicker__month-container {
    width:100%;
}
.react-datepicker__day-names,.react-datepicker__week {
    display: flex;
    justify-content: space-around;
}
.react-datepicker__header  {
    background: #1b3e77 !important;
}
.react-datepicker__day-name,.react-datepicker__current-month {
    color: #fff !important;
    padding: 8px;
}
.plannerWrapper {
    display: flex;
    align-items: flex-start;
}
.plannerWrapper>div{
    width: 40%;
    padding: 22px;
}
.plannerWrapper h3 {
    padding: 22px;
}
.plannerWrapper>ul {
    width: 60%;
    padding: 0;
    margin-top: 22px;
    border: 1px solid #f5f5f5;
}
.ks-cboxtags>div {
    padding: 6px 12px;
}
.react-datepicker {
    border-color: #f5f5f5 !important;
}
.advanceForm {
    padding: 32px;
    padding-top: 72px;
}
.advanceForm .timeStatus {
    padding: 32px;
}
.advanceForm .plannerWrapper {
    padding: 0;
}
.colSc,.scBg {
    background-color: #ccc;
}
.advanceForm h1 {
    padding: 22px 0;
    font-size: 22px;
    font-weight: 500;
    margin-top: 32px;
}
.plannerWrapper th {
    font-weight: 500;
}
.advanceForm table {
    width: 100%;
    text-align: justify;
    line-height: 32px;
    margin-bottom: 112px;
    border: 1px solid #f5f5f5;
    border-collapse: collapse;
}
.advanceForm thead {
    background: #1b3e77;
    color: #fff;
}
.advanceForm td {
    font-size: 15px;
}
.react-datepicker__navigation-icon {
    top: 8px !important;
}
.advanceForm th,.advanceForm td {
    padding: 4px;
    line-height: 25px;
    font-size: 15px;
    font-weight: 500;
}
.advanceForm th,.advanceForm td, .advanceForm table {
    border: 1px solid #cccc;
}
.advanceForm table span {
    background: #be1d2d;
    border-color: #be1d2d;
    border-radius: 4px;
    color: #fff;
    padding: 4px 10px;
    cursor: pointer;
}
.ks-cboxtags>h1 {
    padding: 10px;
    margin-top: 0;
    text-align: center;
    width: 100%;
    background: #1b3e77;
    color: #fff;
    border-radius: 4px;
    margin-bottom: 32px;
    font-size: 18px;
}
.blacklistContainer .antdAccordionWrapper, .whitelistContainer .antdAccordionWrapper {
    max-height: 250px;
    overflow: auto;
}
.blacklistMenuWrapper .dashboard>.error {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    padding: 32px 0;
}
/* ul.same li:last-child{
    display: none;
} */
.realTime {
    padding: 0 18px;
    line-height: 32px;
}
.advanceForm .timeStatus.bgWhite>h1 {
    padding-left: 16px;
}
.ks-cboxtags .error {
    padding: 12px;
    font-size: 16px;
}