@media(max-width:767px) {
    .responsive {
        overflow: auto;
    }
    .responsive table {
        min-width: 900px;
    }
    .advanceForm {
        padding: 0px;
        padding-top: 32px;
    }
    .ks-cboxtags,.plannerWrapper>div {
        padding: 0;
    }
    .advanceForm .timeStatus {
        padding: 12px;
    }
    .ant-tour.css-k83k30 {
        max-width: 99vw;
    }
    .plannerWrapper {
        flex-direction: column;
    }
    .plannerWrapper>div,.plannerWrapper>ul {
        width: 100%;
    }
    .loginWrapper .flex {
        width: 90%;
    flex-direction: column;
    }
    .loginWrapper .flex img {
        width: 60%;
        margin: auto;
        display: block;
    }
    .otpWrapper {
        flex-grow: 1;
    }
    .loginWrapper .ant-form-item-control {
        margin: 0;
    }
    .pcSwitchWrapper {
        padding: 22px;
        padding-top: 52px;
    }
    .pcDisabled {
        max-width: 100%;
        margin: 22px;
    }
    .pcSwitchWrapper h1 {
        margin-bottom: 32px;
    }
    .tablesWrapper {
        padding: 0;
    }
    .singleTime_status {
        flex-direction: column;
        align-items: start;
    }
    .timeFrame {
        overflow: auto;
        padding-bottom: 12px;
    }
    .timeFrame>div {
        width: 120%;
    }
    .timeTitle {
        width: 100%;
        padding: 12px;
        margin-bottom: 12px;
    }
    .advancePage .advanceComponent>.ant-row>div:first-child .antdAccordionWrapper {
        display: none;
    }
    .advanceComponent .ant-select {
        width: 50% !important;
        margin: 22px;
    }
    .advanceComponent .ant-select-selector {
        height: 40px !important;
        padding-top: 3px !important;
    }
    .ant-select-selection-item {
        font-weight: 500;
    }
    .dashboard h4{
        padding: 12px 20px;
    }
    ul.ks-cboxtags li label {
        padding: 6px 8px;
        font-size: 14px;
    }
    ul.ks-cboxtags li label::before{
        padding-right: 0;
    }
    .rightNav_items {
        display: none;
    }
    .tablesWrapper .ant-col {
        margin: 0;
        padding: 12px;
    }
    .ant-tour-placement-bottom {
        max-width: 340px !important;
    }
}


@media(min-width:768px) {
    .headerWrapper button{
        display: none;
    }
    .advanceComponent .ant-select {
        display: none;
    }
}